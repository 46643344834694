<!--
 * @Author: lzh
 * @Date: 2022-07-08 16:51:54
 * @LastEditors: lbh
 * @LastEditTime: 2023-02-04 11:49:20
 * @Description: file content
-->
<template>
  <div
    class="partner-box"
    :class="`edit_${Xindex}_items`"
    @click="clickItem('items', Xindex)"
  >
    <div class="item-box">
      <div
        class="item"
        :style="index % 4 == 0 ? 'margin-left:0;' : ''"
        v-for="(item, index) in configs.items"
        :key="index"
      >
        <img
          class="img"
          :src="item.img"
          :alt="item.alt"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getByName } from '@/js/utils/default_config';
export default {
  name: 'self-partner',
  props: {
    configs: {
      default () {
        return {
          items: [],
        };
      },
    },
    Xindex: {
      default () {
        return '';
      },
    },
    tabIndex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      isEdit: false,
    };
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  methods: {
    clickItem (e, x, t) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x, t);
      }
    },
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
  },
};
</script>

<style lang="less" scoped>
.partner-box {
  padding: 12px;
  padding-bottom: 24px;
  box-sizing: border-box;
  .item-box {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: calc((100% - 40px) / 4);
      height: calc((100vw - 56px) / 8);
      margin-left: 10px;
      margin-top: 10px;
      position: relative;
      .remove {
        position: absolute;
        top: 6px;
        right: 10px;
        font-size: 32px;
        z-index: 10;
        &:hover {
          color: var(--themeColor);
        }
      }
      .img {
        width: 100%;
        height: 100%;
      }
      &:hover {
        box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.25),
          -2px -2px 8px 1px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
      }
    }
    .add {
      border: 1px solid #ccc;
      width: calc((100% - 40px) / 4);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      margin-top: 10px;
      margin-left: 10px;
      min-height: calc((100vw - 56px) / 10);
      &:hover {
        box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.25),
          -2px -2px 8px 1px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
      }
    }
  }
}
</style>
